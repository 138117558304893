import { h } from '@fullcalendar/core/preact';
import { Tag } from 'antd';
import moment from 'moment';
import Moment from 'moment';

const request = async (url, { method = "GET", params } = {}, sorter = null) => {
  let token = localStorage.getItem("@App:token");

  let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  console.log(token);
  if (token !== 'undefined') {
    headers.Authorization = `Bearer ${token}`;
  }

  let requestOptions = {
    method,
    headers: headers,
    redirect: "follow",
  };
  
  if (method === "GET") {
    url += "?" + new URLSearchParams(params).toString();
  }

  if (
    sorter !== undefined &&
    sorter !== null &&
    Object.keys(sorter).length > 0
  ) {
    const orderBy = sorter?.order === "ascend" ? "asc" : "desc";
    url += `&order[${sorter?.field}]=${orderBy}`;
  }

  if (method !== "GET") {
    console.log('Helper', JSON.stringify(params));
    requestOptions.body = JSON.stringify(params);
  }

  try {
    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (error) {
    console.log("error >>", url, error);
  }
};

export const get = (url, params, sorter) => request(url, { params }, sorter);
export const post = (url, params) => request(url, { method: "POST", params });
export const del = (url, params) => request(url, { method: "DELETE", params });
export const put = (url, params) => request(url, { method: "PUT", params });
export const patch = (url, params) => request(url, { method: "PATCH", params });

export const formatDateTime = (date) => {
  const d = new Date(date);
  return Moment(d).format('DD MMM YYYY HH:mm:ss');
}

export const formatDateToISO8601 = (date) => {
  // const d = new Date(date);
  // return Moment(d).format('DD-MMM-YYYY');
  return date.split(" ")[0].split("/").reverse().join('-');
}

export const formatDate = (date) => {
  const d = new Date(date);
  return Moment(d).format('DD/MM/YYYY');
}

export function isEmpty(str) {
  return (
    str === undefined ||
    str === null ||
    !str ||
    (typeof str === "string" && str.trim().length === 0)
  );
}

export function getValueObjectSelect(value) {
  if (value?.value !== undefined) {
    return value?.value;
  }
  
  return value;
}

export function prepareOptionValue(data) {
  if (! isEmpty(data?.value) && ! isEmpty(data.children)) {
    return {value: data?.value, label: data.children};
  }
  
  return data;
}

export function getItemsNotUndefined(obj) {
  let objectData = Object.entries(obj);
  let objectNotUndefined = [];

  objectData.forEach((data) => {
    if (data[1] !== undefined && data[1] !== null && data[1] !== "") {
      objectNotUndefined[data[0]] = data[1];
      objectNotUndefined.length += 1;
    }
  });
  return Object.assign({}, objectNotUndefined);
}

export function onReadyResponse(response, notify, targetMessage, messageSuccess, onLoading, onReloadGrid, reloadGrid, form, type)
{
  if (response?.success === true) {
    if (! isEmpty(notify)) {
      if (type !== undefined && type === 'notification') {
        notify("success", targetMessage, messageSuccess, [], 'notification');
      } else {
        notify("success", targetMessage, messageSuccess);
      }
    }

    if (! isEmpty(onReloadGrid)) {
      onReloadGrid(! reloadGrid);
    }

    if (! isEmpty(form)) {
      form.resetFields();
    }

    if (! isEmpty(onLoading)) {
      onLoading(false);
    }
  } else {
    if (! isEmpty(notify)) {
      if (type !== undefined && type === 'notification') {
        notify("error", targetMessage,  response.message, response.errors, 'notification');
      } else {
        notify("error", targetMessage,  response.message, response.errors);
      }
    }

    if (! isEmpty(onLoading)) {
      onLoading(false);
    }
  }
}

export function getListErrorsLaravel(list)
{
  if (list instanceof Object && ! isEmpty(list)) {
    let errors = [];
    const listErrors = Object.values(list);
    listErrors.map((error) => {
      errors.push(error[0]);
      return error;
    })

    return errors;
  }

  return [];
}

export function prepareFiltersForTableWithSearchPaginated(filters, search) {
  const filtersMerged = [];

  let verifyExistsSearch = false;
  filters.map((filter) => {
    if (search.column === filter.column) {
      filter.value = search.value;
      verifyExistsSearch = true;
    }

    if (!isEmpty(filter.value)) {
      filtersMerged.push(filter);
    }
  });

  if (verifyExistsSearch === false) {
    filtersMerged.push(search);
  }

  return filtersMerged;
}

export function formatDateDBtoBR(date) {
  let dateBr = moment(date);

  if (!dateBr.isValid()) {
    return "--/--/--";
  }

  return moment(date).format("DD/MM/YYYY");
}

export function onReponseAgendaAndGetEvents(response) {
  const listEvents = [];

  if (
    !isEmpty(response?.data) && 
    Array.isArray(response?.data)
) {
    response?.data.map((agenda) => {
        let teams = '';
        if (!isEmpty(agenda.teamsData) && Array.isArray(agenda.teamsData)) {                    
            agenda.teamsData.map((team) => {
                teams += `${team.name} - `;
                return team;
            })
        }

        let periodTimeStart = '';
        let periodTimeEnd = '';
        switch (agenda.period) {
            case 'MANHA':
                periodTimeStart = '07:00';
                periodTimeEnd = '12:00';
                break;
            case 'TARDE':
                periodTimeStart = '12:00';
                periodTimeEnd = '18:00';
                break;
            case 'NOITE':
                periodTimeStart = '18:00';
                periodTimeEnd = '22:00';
                break;
            default:
                periodTimeStart = '07:00';
                periodTimeEnd = '12:00';
                break;
        }
        
        listEvents.push({
            title  : `${agenda.period} - Sala ${agenda.classroom.name} - Turmas ${teams}`,
            extendedProps   : agenda,
            start  : `${agenda.date_agenda} ${periodTimeStart}`,
            end    : `${agenda.date_agenda} ${periodTimeEnd}`,
        })
        return agenda;
    })
  }

  return listEvents;
}

export const getColorPeriod = (period) => {
  if (period === 'MANHA') {
   return '#2db7f5';
  }

  if (period === 'TARDE') {
    return '#87d068';
  }

  if (period === 'NOITE') {
    return '#3b5999';
  }
};

export const getStringTeamsByArray = (teams) => {
  let teamsList = '';
  if (!isEmpty(teams) && Array.isArray(teams)) {                    
    teams.map((team) => {
      teamsList += `${team.name} - `;
      return team;
    })
  }

  return teamsList;
}

export const getEventCalendarData = (event) => (
  <>
    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
      <b>Responsável:</b> <br />{ event.user.name }
    </div>
    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
      <b>Turmas: </b> <br />{ getStringTeamsByArray(event.teamsData) }
    </div>
    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
      <b>Sala/Laboratório:</b> <br />{ event.classroom.name }
    </div>
  </>
)

export const getHolidayByDate = (date) => {
  const holidays = JSON.parse(localStorage.getItem("@App:hodilays"));

  let holiday = null;
  console.log(date);
  holidays.map((hday) => {
    if (hday.date_holiday === date) {
      holiday = hday;
    }
    return hday;
  })

  return holiday;
} 