import { Alert, notification } from "antd";
import { WarningOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { createContext, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { getListErrorsLaravel } from "utils/Helper";

const NotificationHandlerContext = createContext(
  {}
);

export function NotificationHandlerProvider({ children }) {
  const [api, contextHolder] = notification.useNotification();

  const [message, setMessage] = useState("");
  const [type, setType] = useState('error');
  const [target, setTarget] = useState("");
  const [duration, setDuration] = useState();
  const [list, setList] = useState();
  const [notificationType, setNotificationType] = useState();

  const DEFAULT_DURATION_MESSAGE = 10000;

  const notify = (type, target, message, list, notificationType, duration) => {
    const durationMessage = duration === undefined ? DEFAULT_DURATION_MESSAGE : duration;

    setMessage(message);
    setType(type);
    setTarget(target);
    setDuration(durationMessage);
    setList(
      getListErrorsLaravel(list)
    );
    setNotificationType(notificationType);

    setTimeout(() => {
      setTarget('');
      setMessage('');
      setList([]);
    }, durationMessage);

    return true;
  }

  useEffect(() => {
    if (notificationType !== undefined && notificationType === 'notification' && message !== '') {
      api[type]({
        message: message,
        duration: duration !== undefined ? duration / 1000 : 5
      });
    }
  }, [message]);

  const onCloseAlert = () => {
    setTarget('');
    setMessage('');
    setList([]);
  }

  const showMessage = (targetMessage) => {    
    return (
      (notificationType === undefined || notificationType === "alert" ) && targetMessage === target ?
        <Alert 
          message={message} 
          description={(
              list !== undefined &&
              list.length > 0 &&
                <ul>
                  {
                    list.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))
                  }
                </ul>
          )}
          type={type} 
          onClose={() => onCloseAlert()}
          className="mb-4" 
          closable 
          showIcon 
          icon={type === 'error' ? <WarningOutlined /> : <CheckCircleOutlined />} 
        />
      : <div></div>
    );
  }
    
  return (
    <NotificationHandlerContext.Provider
      value={{
        notify,
        message,
        type,
        duration,
        list,
        notificationType,
        showMessage,
        contextHolder
      }}
    >
      {children}
      {contextHolder}
    </NotificationHandlerContext.Provider>
  );
}

export const NotificationHandlerConsumer = NotificationHandlerContext.Consumer;

export function useNotificationHandlerContext() {
  const context = useContext(NotificationHandlerContext);
  return context;
}

export default NotificationHandlerContext;
